import { getItem, setItem } from './localStorage';

const tokenUtils = {
  getTokenData() {
    let tokenDataStr = getItem('tokenData');
    return tokenDataStr ? JSON.parse(tokenDataStr) : null;
  },

  getIdToken() {
    let tokenData = this.getTokenData();
    return tokenData ? tokenData['id_token'] : null;
  },

  getRefreshToken() {
    let tokenData = this.getTokenData();
    return tokenData ? tokenData['refresh_token'] : null;
  },

  getSessionId() {
    let tokenData = this.getTokenData();
    return tokenData ? tokenData['sessionId'] : null;
  },

  saveNewIDToken(id_token) {
    let tokenData = this.getTokenData();
    if (!tokenData) return null;
    tokenData['id_token'] = id_token;
    return Promise.resolve(setItem('tokenData', JSON.stringify(tokenData)));
  },

  saveNewRefreshToken(refreshToken) {
    let tokenData = this.getTokenData();
    if (!tokenData) return null;
    tokenData['refresh_token'] = refreshToken;
    return Promise.resolve(setItem('tokenData', JSON.stringify(tokenData)));
  },
};
export default tokenUtils;
