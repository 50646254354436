import axios from 'store/axios_sso';
import tokenUtils from 'utils/tokenData';
export const refreshToken = () => {
  return axios.post('/refresh').then(res => {
    // console.log(res.data);
    return res.data;
  });
};
export const refreshSSOV2 = () => {
  let tenantCode = '';
  if (window.location.hostname) {
    tenantCode = window.location.hostname.split('.')[0];
  }
  let client_id = encodeURIComponent(process.env.REACT_APP_SSO_CLIENT_ID);
  // return httpSSOService.post("/refresh");
  let refreshToken = tokenUtils.getRefreshToken();

  return axios.get(
    `/refresh-token-v2?tenantCode=${tenantCode}&clientId=${client_id}&refreshToken=${refreshToken}`,
    { withCredentials: true },
  );
};
