import axios from 'axios';
import qs from 'qs';
import axiosSso from './axios_sso';
import * as ssoService from './sso/service';
import { getItem, setItem } from 'utils/localStorage';
import { openSessionExpiredModal } from 'utils/helper';
import tokenUtils from 'utils/tokenData';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
  timeout: 100000,
});

// Request interceptor for API calls
instance.interceptors.request.use(
  async config => {
    const idToken = tokenUtils.getIdToken();
    const sessionId = tokenUtils.getSessionId();
    config.headers = {
      Authorization: `Bearer ${idToken}`,
      TenantCode: 'mobifone.vn',
      SessionId: sessionId,
      common: { 'Content-Type': 'application/json; charset=utf-8' },
    };
    return config;
  },
  error => {
    Promise.reject(error);
  },
);

let refreshTokenPromise = null;

instance.interceptors.response.use(
  res => res,
  async error => {
    const originalRequest = error.config;
    if (
      error &&
      (error?.response?.data?.error === 'authenticate.BlacklistJwtException' ||
        error?.response?.data?.message === 'refreshToken.ExpiredJwtException' ||
        error?.data?.message === 'authenticate.jwtExpired')
    ) {
      let sessionExpired = await getItem('sessionExpired');
      if (!sessionExpired) {
        await openSessionExpiredModal();
      }
      return null;
    } else if (
      error &&
      error?.response?.data?.error === 'authenticate.jwtExpired' &&
      !originalRequest._retry
    ) {
      // const tokenData = await axiosSso.post('/refresh');
      // if (tokenData) {
      //   await setItem('id_token', tokenData.data?.id_token);
      //   await setItem('expires_in', tokenData.data?.expires_in);
      //   await setItem('createTokenTime', Date.now());
      // }
      // originalRequest._retry = true;
      // return instance(originalRequest);
      if (!refreshTokenPromise) {
        refreshTokenPromise = refreshToken().then(() => {
          originalRequest._retry = true;
          refreshTokenPromise = null;
        });
      }
      return refreshTokenPromise.then(() => {
        return instance(originalRequest);
      });
    } else {
      return Promise.reject(error);
    }
  },
);

async function refreshToken() {
  const response = await ssoService.refreshSSOV2();
  if (response.status !== 200) {
    console.log('refreshTokenInternal log out ' + response.status);
  } else {
    // console.log("refreshTokenInternal id_token " + response.data.id_token);
    await tokenUtils.saveNewIDToken(response.data?.id_token);
    await tokenUtils.saveNewRefreshToken(response.data?.refresh_token);
    // await setItem('id_token', response.data?.id_token);
    // await setItem('refresh_token', response.data?.refresh_token);
  }
}

export default instance;
