import { memo, useEffect } from 'react';
import { removeItem } from 'utils/localStorage';
import { history } from 'utils/history';
const LogoutCallback = memo(() => {
  useEffect(() => {
    removeToken();
  }, []);

  const removeToken = () => {
    removeItem('tokenData');
    removeItem('sessionId');
    removeItem('sessionExpired');
    history.replace('/');
  };

  return <div className="logout-callback">Main logout</div>;
});

export default LogoutCallback;
