import { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { setItem } from 'utils/localStorage';
import { history } from 'utils/history';
import { Main } from './components/Loadable';
import './style.css';

const LoginCallback = memo(() => {
  useEffect(() => {
    getToken();
  }, []);

  const getToken = () => {
    // console.log('LoginCallback');
    let hash = window.location.hash;
    let loginCallbackPrefix = '/loginCallback';
    hash.startsWith(loginCallbackPrefix) &&
      (hash = hash.substr(
        hash.indexOf(loginCallbackPrefix) + loginCallbackPrefix.length,
      ));

    const tokenData = {};

    hash
      .substr(1)
      .split('&')
      .forEach(item => {
        const elements = item.split('=');
        // console.log(elements);
        tokenData[decodeURI(elements[0])] = decodeURI(elements[1]);
      });

    if (tokenData) {
      // do something
      console.log(tokenData);
      // setItem('id_token', tokenData?.id_token);
      // setItem('refresh_token', tokenData?.refresh_token);
      // setItem('expires_in', tokenData?.expires_in);
      // setItem('token_type', tokenData?.token_type);
      setItem('SMART_OFFICE_TOKEN', tokenData?.id_token);
      setItem('tokenData', JSON.stringify(tokenData));
      setItem('sessionId', tokenData?.sessionId);
      history.push('/dashboard');
    }
  };

  return (
    <div className="login-callback">
      <Main />
    </div>
  );
});

LoginCallback.propTypes = {
  setToken: PropTypes.func.isRequired,
};
LoginCallback.defaultProps = {
  setToken: null,
};

export default LoginCallback;
