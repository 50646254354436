import { Route } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import useLoadingApp from 'hooks/useLoadingApp';
import tokenUtils from 'utils/tokenData';

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const loadingApp = useLoadingApp();

  return (
    loadingApp || (
      <Route
        {...rest}
        render={props => {
          if (!tokenUtils.getIdToken()) {
            return (
              <Redirect
                to={{
                  pathname: '/dashboard',
                }}
              />
            );
          } else {
            return <Component {...props} />;
          }
          // } else {
          //   let pathname = history.location.pathname;
          //   if (!LIST_PUBLIC_ROUTE.some(item => pathname.includes(item)))
          //     setItem(TYPE_LOCAL_STORAGE.URL_REDIRECT, pathname);
          //   return (
          //     <Redirect
          //       to={{
          //         pathname: '/account/login',
          //       }}
          //     />
          //   );
          // }
        }}
      />
    )
  );
};
