import axios from 'axios';
import qs from 'qs';
import { getItem } from '../utils/localStorage';
import { openSessionExpiredModal } from 'utils/helper';
import tokenUtils from 'utils/tokenData';

const instance = axios.create({
  baseURL: process.env.REACT_APP_SSO_URL,
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
  timeout: 100000,
});

// Request interceptor for API calls
instance.interceptors.request.use(
  async config => {
    const refreshToken = tokenUtils.getRefreshToken();
    config.headers = {
      RefreshToken: refreshToken,
      ClientID: process.env.REACT_APP_SSO_CLIENT_ID,
      common: { 'Content-Type': 'application/json; charset=utf-8' },
    };
    return config;
  },
  error => {
    Promise.reject(error);
  },
);

instance.interceptors.response.use(
  res => res,
  error => {
    console.log(error.response);
    if (error && error?.response?.status === 401) {
      openSessionExpiredModal();
      return null;
    } else {
      return Promise.reject(error);
    }
  },
);

export default instance;
